import { i18n } from '@/config/i18n-setup'

console.log(i18n, 'i18n')

export const regionNames = [
    {
        name: i18n.t('dashboard.regions.abai'),
        key: "Abai",
        id: "KZ10",
        x: 793.2,
        y: 270
    },
    {
        name: i18n.t('dashboard.regions.akmola'),
        key: "Akmola",
        id: "KZ11",
        x: 566.8,
        y: 130
    },
    {
        name: i18n.t('dashboard.regions.aktobe'),
        key: "Aktobe",
        id: "KZ15",
        x: 302.8,
        y: 275.2
    },
    {
        name: i18n.t('dashboard.regions.almaty'),
        key: "Almaty",
        id: "KZ19",
        x: 707.7,
        y: 395
    },
    {
        name: i18n.t('dashboard.regions.almaty_city'),
        key: "Almaty (city)",
        id: "KZ75",
        x: 730,
        y: 455
    },
    {
        name: i18n.t('dashboard.regions.atyrau'),
        key: "Atyrau",
        id: "KZ23",
        x: 206.7,
        y: 306.3
    },
    {
        name: i18n.t('dashboard.regions.jambyl'),
        key: "Jambyl",
        id: "KZ31",
        x: 600,
        y: 370
    },
    {
        name: i18n.t('dashboard.regions.ulytau'),
        key: "Ulytau",
        id: "KZ62",
        x: 522.7,
        y: 306.1
    },
    {
        name: i18n.t('dashboard.regions.kostanay'),
        key: "Kostanay",
        id: "KZ39",
        x: 426.6,
        y: 150.4
    },
    {
        name: i18n.t('dashboard.regions.kyzylorda'),
        key: "Kyzylorda",
        id: "KZ43",
        x: 428.1,
        y: 381.7
    },
    {
        name: i18n.t('dashboard.regions.mangystau'),
        key: "Mangystau",
        id: "KZ47",
        x: 212.6,
        y: 417.5
    },
    {
        name: i18n.t('dashboard.regions.north_kazakhstan'),
        key: "North Kazakhstan",
        id: "KZ59",
        x: 555,
        y: 40
    },
    {
        name: i18n.t('dashboard.regions.astana'),
        key: "Astana",
        id: "KZ71",
        x: 605,
        y: 195
    },
    {
        name: i18n.t('dashboard.regions.pavlodar'),
        key: "Pavlodar",
        id: "KZ55",
        x: 702.5,
        y: 146.2
    },
    {
        name: i18n.t('dashboard.regions.shymkent_city'),
        key: "Shymkent (city)",
        id: "KZ79",
        x: 570,
        y: 480
    },
    {
        name: i18n.t('dashboard.regions.turkestan'),
        key: "Turkestan",
        id: "KZ61",
        x: 535,
        y: 420
    },
    {
        name: i18n.t('dashboard.regions.west_kazakhstan'),
        key: "West Kazakhstan",
        id: "KZ27",
        x: 138.5,
        y: 225.3
    },
    {
        name: i18n.t('dashboard.regions.karaganda'),
        key: "Karaganda",
        id: "KZ35",
        x: 655.4,
        y: 281.3
    },
    {
        name: i18n.t('dashboard.regions.jetisu'),
        key: "Jetisu",
        id: "KZ33",
        x: 761.3,
        y: 350
    },
    {
        name: i18n.t('dashboard.regions.east_kazakhstan'),
        key: "East Kazakhstan",
        id: "KZ63",
        x: 889,
        y: 235
    }
]

export const regionIDs = [
    "KZ10",
    "KZ11",
    "KZ15",
    "KZ19",
    "KZ75",
    "KZ23",
    "KZ31",
    "KZ62",
    "KZ39",
    "KZ43",
    "KZ47",
    "KZ59",
    "KZ71",
    "KZ55",
    "KZ79",
    "KZ61",
    "KZ27",
    "KZ35",
    "KZ33",
    "KZ63"
]